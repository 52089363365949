import axios from "axios";

export class product
{
    constructor(session) {
        this.user = session ? session.user : null;
        this.id_company = session ? session.id_company : null;
        this.id_branch = session ? session.id_branch : null;
        this.token = session ? session.token : null;

        this.id = null;
        this.id_pos_family = null;
        this.name = null;
        this.key_name = null;
        this.cost = 0.00;
        this.minimum = null;
        this.maximum = null;
        this.existance = null;
        this.price = 0.00;
        this.created_by = null;
        this.created = null;
        this.modified_by = null;
        this.modified = null;
        this.image = null;
        this.iva_percentage = 0.00;
        this.codebar = null;
        this.ieps_percentage = 0.00;
        this.tpv = null;
        this.sat_code = null;
        this.comision = null;
        this.comision_percentage = null;
        this.ish_percentage = null;
        this.is_active = true;
        this.list_prices = [];
    }

    async all() {
        let response = await axios.get("tpv/products", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async save() {
        let response = await axios.post("tpv/products", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async update() {
        let response = await axios.put("tpv/products/" + this.id, this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async delete() {
        let response = await axios.delete("tpv/products/" + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }
}